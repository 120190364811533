<i18n>
{
  "de": {
    "login": "Login",
    "emailPlaceholder": "E-Mail",
    "passwordPlaceholder": "Passwort",
    "loginErrorMessage": "Sie haben entweder eine unbekannte E-Mail-Adresse oder ein falsches Passwort eingegeben!",
    "passwordForgotten": "Passwort vergessen",
    "passwordForgottenUrl": "/password/reset",
    "demoHint": "Falls Sie Ihr Passwort vergessen haben oder noch keinen Compass-Zugang besitzen, wenden Sie sich bitte an ruggle@bs2.ch oder +41 44 275 25 00."
  }
}
</i18n>

<template>
  <main-layout class="login-page">
    <template #default>
      <form @submit.prevent="handleLogin">
        <input type="text" inputmode="email" v-model="email" :class="{ 'login-error': loginFailed }" maxlength="50" :placeholder="$t('emailPlaceholder')" autocomplete="username" autocapitalize="off" autocorrect="off">
        <input type="password" v-model="password" :class="{ 'login-error': loginFailed }" maxlength="50" :placeholder="$t('passwordPlaceholder')" autocomplete="current-password">
        <p v-if="loginFailed" class ="error-message">{{ $t('loginErrorMessage') }}</p>
        <button class="login-button button" type="submit" :disabled="submitDisabled" :class="{ 'login-error': loginFailed }">{{ $t('login') }}</button>
        <p class="demo-hint">{{ $t('demoHint') }}</p>
      </form>
      <!-- section>
        <a href="#" @click.prevent="handlePasswordForgotten">{{ $t('passwordForgotten') }}</a>
      </section -->
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import router from '@/router'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn',
      loggingIn: 'account/loggingIn',
      loginFailed: 'account/loginFailed'
    }),
    submitDisabled () {
      return this.loggingIn || this.email === '' || this.password === ''
    }
  },
  watch: {
    loggedIn (to, from) {
      if (to) {
        router.replace(this.$route.query.redirect ? this.$route.query.redirect : '/')
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'account/login',
      logout: 'account/logout'
    }),
    handleLogin () {
      const { email, password } = this
      if (email && password) {
        this.login({ email, password })
      }
    }
  },
  created () {
    // reset login status
    this.logout()
  },
  components: {
    MainLayout
  }
}
</script>

<style>
.login-page form {
  display: grid;
  gap: 0.7rem;
  max-width:500px;
  margin:var(--spacing-l) auto;
}

.login-page p.demo-hint {
  margin-top:var(--spacing-m);
  color: var(--highlight-color);
}

.login-page p.error-message {
  color: var(--highlight-color);
  margin: 0;
}

.login-page input.login-error {
  border-color: var(--hairline-color);
}

.login-page > section {
  margin-top: 2.8rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.7rem;
  justify-items: start;
}
</style>
